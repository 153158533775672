import { IView } from '@wf-mfe-maestro/api';

import { IRecordTypeWithoutFields } from '../types';
import { IRootState } from './store';

export const initialRecordType: IRecordTypeWithoutFields = {
  id: '',
  displayName: '',
  color: '',
  icon: '',
  primaryFieldId: '',
  description: '',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
  isExternal: false,
  externalOptions: null,
  permission: null,
  alias: '',
};

export const initialState: IRootState = {
  fields: [],
  fieldsMap: {},
  recordType: initialRecordType,
  workspace: null,
  availableFieldTypes: [],
  selectedView: {} as IView,
  viewList: [],
  fieldsRTBE: {
    added: [],
    updated: [],
    deleted: [],
  },
  presenceRTBE: {
    showCollaborators: false,
    presentUsers: [],
    presentUsersByRecord: {},
  },
};
