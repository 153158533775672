import { ITableView, ViewType } from '@wf-mfe-maestro/api';

import { ReadonlyField } from '../../../state/fieldState';
import { mapColumnMetaData } from './mapColumnMetaData';

export const formatTableViewMetaData = (
  fields: ReadonlyField[],
  metadata: ITableView['metadata'],
  fieldMap: Record<string, ReadonlyField>
): ITableView['metadata'] => ({
  ...metadata,
  type: ViewType.TABLE,
  rowOrder: metadata?.rowOrder || [],
  rowHeight: metadata?.rowHeight || undefined,
  showThumbnail: metadata?.showThumbnail || false,
  columnMetadata: mapColumnMetaData(fields, metadata?.columnMetadata || [], fieldMap),
});
