import { useCallback } from 'react';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import { AccessPermission, MaestroObjects } from '@wf-mfe-maestro/api';
import { hasEditPermission, hasManagePermission, hasViewPermission } from '@wf-mfe-maestro/utils';

import { useSelectedViewData } from '../view';
import { useRecordTypeData } from '../useRecordTypeData/useRecordTypeData';
import { useWorkspaceData } from '../useWorkspaceData/useWorkspaceData';

export const usePermissions = () => {
  const isPermissionsSplitEnabled = useIsTreatmentEnabled('maestro-permissions');
  const isViewPermissionsSplitEnabled = useIsTreatmentEnabled('maestro-view-permissions');
  const { selectedView } = useSelectedViewData();
  const { recordType } = useRecordTypeData();
  const { workspace } = useWorkspaceData();

  const checkPermission = useCallback(
    (objectType: MaestroObjects, permission: AccessPermission) => {
      if (!isPermissionsSplitEnabled) {
        return true;
      }

      if (objectType === MaestroObjects.VIEW && !isViewPermissionsSplitEnabled) {
        return true;
      }

      let objectPermission: AccessPermission | null;

      if (objectType === MaestroObjects.RECORD_TYPE) {
        objectPermission = recordType.permission;
      } else if (objectType === MaestroObjects.VIEW) {
        objectPermission = selectedView.permission;
      } else {
        objectPermission = workspace?.permission || null;
      }

      if (permission === AccessPermission.MANAGE) {
        return hasManagePermission(objectPermission);
      }

      if (permission === AccessPermission.EDIT) {
        return hasEditPermission(objectPermission);
      }

      if (permission === AccessPermission.VIEW) {
        return hasViewPermission(objectPermission);
      }

      return false;
    },
    [isPermissionsSplitEnabled, isViewPermissionsSplitEnabled, recordType, selectedView, workspace]
  );

  return { checkPermission };
};
