import { BehaviorSubject, combineLatestWith, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { Field, IView } from '@wf-mfe-maestro/api';

import { enhanceViewData } from '../utils/viewEnhancements/enhanceViewData';
import { internalViewSubject$, selectedViewSubject$ } from './viewState';
import { FieldBehaviorSubject } from './FieldBehaviorSubject';

export interface IFieldState {
  fields: ReadonlyField[];
  added: ReadonlyField[];
  updated: ReadonlyField[];
  deleted: string[];
  recordTypeId: string;
}

export type ReadonlyField = Readonly<Field>;

// TODO: This will be removed after full refactor view-service
// This function is called before the next value is emitted by the BehaviorSubject.
const updateView = (fieldsState: IFieldState) => {
  const fieldsMap = fieldsState.fields.reduce<Record<string, ReadonlyField>>((acc, filedItem) => {
    acc[filedItem.id] = filedItem;
    return acc;
  }, {});
  fieldMapSubject$.next(fieldsMap);

  const view = internalViewSubject$.getValue();

  if (view.id && fieldsState.fields.length > 0 && fieldsState.recordTypeId === view.recordTypeId) {
    const filteredView = enhanceViewData(view, fieldsMap, fieldsState.fields);
    selectedViewSubject$.next(filteredView);
  } else if (fieldsState.recordTypeId !== view.recordTypeId) {
    selectedViewSubject$.next({} as IView);
  }
};

export const fieldMapSubject$ = new BehaviorSubject<Record<string, ReadonlyField>>({});
export const fieldsStateSubject$ = new FieldBehaviorSubject({ beforeNext: updateView });

export const fieldsSubject$ = new BehaviorSubject<ReadonlyField[]>([]);

export const addedFields$ = fieldsStateSubject$.pipe(
  map((data) => data.added),
  distinctUntilChanged(),
  filter((added) => added.length > 0)
);

export const updatedFields$ = fieldsStateSubject$.pipe(
  map((data) => data.updated),
  distinctUntilChanged(),
  filter((updated) => updated.length > 0)
);

// TODO: [fieldSubject cleanup] remove after fieldSubject removal
// fieldsSubject$ and fieldsStateSubject$ are kept in sync by the following logic, which will be removed when we will migrated to use only fieldsStateSubject$ and remove fieldsSubject$ completely

let updatingFromFieldsSubject = false;
let updatingFromFieldsStateSubject = false;

// When fieldsSubject$ updates, update fieldsStateSubject$ (unless the update originated from fieldsStateSubject$)
fieldsSubject$
  .pipe(
    tap((fields) => {
      if (!updatingFromFieldsStateSubject) {
        updatingFromFieldsSubject = true;
        fieldsStateSubject$.next({ ...fieldsStateSubject$.getValue(), fields });
        updatingFromFieldsSubject = false;
      }
    })
  )
  .subscribe();

// When fieldsStateSubject$ updates, update fieldsSubject$ (unless the update originated from fieldsSubject$)
fieldsStateSubject$
  .pipe(
    tap(({ fields }) => {
      if (!updatingFromFieldsSubject) {
        updatingFromFieldsStateSubject = true;
        fieldsSubject$.next(fields);
        updatingFromFieldsStateSubject = false;
      }
    })
  )
  .subscribe();

fieldsStateSubject$
  .asObservable()
  .pipe(combineLatestWith(internalViewSubject$.asObservable()))
  .subscribe(([fieldsState, view]) => {
    if (
      view.id &&
      fieldsState.fields.length > 0 &&
      fieldsState.recordTypeId === view.recordTypeId
    ) {
      const filteredView = enhanceViewData(view, fieldMapSubject$.getValue(), fieldsState.fields);
      selectedViewSubject$.next(filteredView);
    }
  });
