import { IRecordTypeWithoutFields } from '../types';

export const initialRecordType: IRecordTypeWithoutFields = {
  id: '',
  displayName: '',
  color: '',
  icon: '',
  primaryFieldId: '',
  description: '',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
  isExternal: false,
  externalOptions: null,
  permission: null,
  alias: '',
};
