import { Field, FieldDTO } from '@wf-mfe-maestro/api';
import { cloneDeep, merge } from 'lodash';

export const getNewFieldsAfterUpdate = (oldFields: Field[], fieldData: FieldDTO) => {
  let updatedField: Field | null = null;
  const fieldsAfterUpdate = oldFields.map((fieldItem) => {
    if (fieldItem.id === fieldData.id) {
      updatedField = merge(cloneDeep(fieldItem), fieldData) as Field;
      return updatedField;
    }
    return fieldItem;
  });
  return [fieldsAfterUpdate, updatedField] as const;
};
